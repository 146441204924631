import React, { useState } from 'react';
import './UserInfoForm.css';
import LoadingModal from './LoadingModal';

const UserInfoForm = ({ onSubmitUserInfo }) => {
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    window.heap.identify(userInfo.email);
    await onSubmitUserInfo(userInfo);
    setLoading(false);
  };

  return (
    <div className="user-info-form">
      <h2>Enter Your Information</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={userInfo.name} onChange={handleChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={userInfo.email} onChange={handleChange} required />
        </label>
        <label>
          Phone:
          <input type="tel" name="phone" value={userInfo.phone} onChange={handleChange} required />
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Next'}
        </button>
      </form>
      <LoadingModal show={loading} />
    </div>
  );
};

export default UserInfoForm;
