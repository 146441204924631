import React from 'react';
import { Circles } from 'react-loader-spinner';
import './LoadingModal.css';

const LoadingModal = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <Circles color="#00BFFF" height={80} width={80} />
        <h2>Generating Quote - Please wait...</h2>
      </div>
    </div>
  );
};

export default LoadingModal;
