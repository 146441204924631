import React, { useState } from 'react';
import { FaHome, FaWarehouse, FaBuilding } from 'react-icons/fa'; // Importing icons
import './HouseConfirmation.css';

const HouseConfirmation = ({ houseData, onConfirm }) => {
  const [floors, setFloors] = useState(houseData?.features?.floorCount || 1);
  const [exteriorMaterial, setExteriorMaterial] = useState('');
  const [roofPitch, setRoofPitch] = useState('');
  const [squareFootage, setSquareFootage] = useState(houseData?.squareFootage || '');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!exteriorMaterial) {
      setError('Please select an exterior material.');
      return;
    }
    if (!roofPitch) {
      setError('Please select a roof pitch.');
      return;
    }
    if (floors < 1) {
      setError('Number of floors must be at least 1.');
      return;
    }
    if (!squareFootage) {
      setError('Please enter the square footage.');
      return;
    }
    setError('');
    onConfirm(true, floors, exteriorMaterial, roofPitch, squareFootage);
  };

  return (
    <div className="house-confirmation">
      <h2>We need a little more information</h2>
      {houseData ? (
        <form onSubmit={handleSubmit}>
          <p>{houseData.formattedAddress}</p>
          <p>Providing us with the following details helps us to generate a more accurate quote tailored to your specific needs:</p>
          <ul>
            <li><strong>Number of Floors:</strong> This helps us estimate the total surface area for cleaning.</li>
            <li><strong>Exterior Material:</strong> Different materials require different cleaning methods and products.</li>
            <li><strong>Roof Pitch:</strong> The angle of your roof affects the cleaning difficulty and time required.</li>
          </ul>
          {error && <p className="error">{error}</p>}
          <div className="input-group">
            <label>Square Footage:</label>
            <input
              type="number"
              value={squareFootage}
              onChange={(e) => setSquareFootage(parseInt(e.target.value, 10))}
              required
            />
          </div>
          <div className="input-group">
            <label>Number of Floors:</label>
            <input
              type="number"
              value={floors}
              onChange={(e) => setFloors(Math.max(1, parseInt(e.target.value, 10)))}
              min="1"
              max="10"
            />
          </div>
          <div className="input-group">
            <label>Exterior Material:</label>
            <select value={exteriorMaterial} onChange={(e) => setExteriorMaterial(e.target.value)} required>
              <option value="">Select Material</option>
              <option value="vinyl">Vinyl</option>
              <option value="wood">Wood</option>
              <option value="fiber-cement">Fiber Cement</option>
              <option value="stucco">Stucco</option>
              <option value="brick">Brick</option>
              <option value="metal">Metal</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="input-group roof-pitch">
            <label>Roof Pitch:</label>
            <div className="roof-options">
              <label className={`roof-option ${roofPitch === 'flat' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="roofPitch"
                  value="flat"
                  checked={roofPitch === 'flat'}
                  onChange={(e) => setRoofPitch(e.target.value)}
                  required
                />
                <FaBuilding />
                <span>Flat</span>
              </label>
              <label className={`roof-option ${roofPitch === 'moderate' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="roofPitch"
                  value="moderate"
                  checked={roofPitch === 'moderate'}
                  onChange={(e) => setRoofPitch(e.target.value)}
                  required
                />
                <FaWarehouse />
                <span>Moderate</span>
              </label>
              <label className={`roof-option ${roofPitch === 'steep' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="roofPitch"
                  value="steep"
                  checked={roofPitch === 'steep'}
                  onChange={(e) => setRoofPitch(e.target.value)}
                  required
                />
                <FaHome />
                <span>Steep</span>
              </label>
            </div>
          </div>
          {error && <p className="error">{error}</p>}
          <div className="button-group">
            <button type="submit">Continue to Quote</button>
          </div>
        </form>
      ) : (
        <div>
          <p>We couldn't find your property details. Please fill out the information manually.</p>
          <button onClick={() => onConfirm(false)}>Go Back</button>
        </div>
      )}
    </div>
  );
};

export default HouseConfirmation;
