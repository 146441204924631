import axios from 'axios';

const base_url = 'https://zencleanbohapi.zencleanwa.com';
//const base_url = 'http://localhost:5002';

export const createQuote = async (quoteRequestData) => {
  const response = await axios.post(`${base_url}/api/quotes/generate`, quoteRequestData);
  return response.data;
};

export const updateQuote = async (id, updateData) => {
  const response = await axios.put(`${base_url}/api/quotes/${id}`, updateData);
  return response.data;
};
