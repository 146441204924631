import React from 'react';
import './ScheduleOrDecline.css';

const ScheduleOrDecline = ({ onSchedule }) => (
  <div className="schedule-or-decline">
    <h2>Thank You for Accepting the Quote!</h2>
    <p>
      We're excited to help you keep your home looking its best. Currently, we are working hard to make our scheduling process completely touchless. However, at this moment, we will need to contact you to finalize the scheduling of your service.
    </p>
    <p>
      You will receive an email with further details shortly. You can also reply to this email, call us at 206-705-8794, or text us to confirm your appointment.
    </p>
    <p>
      Thank you for choosing ZenClean. We appreciate the opportunity to clean your home and will strive to exceed your expectations.
    </p>
    <button className="schedule-button" onClick={onSchedule}>
      Return to ZenClean Website
    </button>
  </div>
);

export default ScheduleOrDecline;
