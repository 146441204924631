import React, { useState } from 'react';
import './QuoteLandingPage.css';

const QuoteLandingPage = ({ onSubmitAddress }) => {
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!street || !city || !zip) {
      setError('Please fill in all fields.');
      return;
    }
    const address = `${street}, ${city}, WA, ${zip}`;
    window.heap.identify(address);
    onSubmitAddress(address);
  };

  return (
    <div className="quote-landing-page">
      <h2>Get an Instant Online Quote</h2>
      <p>We provide fast and accurate quotes for all your home cleaning needs. Simply enter your address below to get started.</p>
      <p>Your address helps us to accurately determine the size and type of your property, ensuring you receive a tailored quote.</p>
      <p><strong>Why Choose Us?</strong></p>
      <ul>
        <li>Experienced and professional staff</li>
        <li>High-quality and eco-friendly cleaning products</li>
        <li>Competitive pricing with no hidden fees</li>
        <li>100% satisfaction guarantee</li>
      </ul>
      <form onSubmit={handleSubmit} className="address-form">
        <div className="form-group">
          <label htmlFor="street">Street Address:</label>
          <input
            type="text"
            id="street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City:</label>
          <input
            type="text"
            id="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="state">State:</label>
          <input type="text" id="state" value="WA" disabled />
        </div>
        <div className="form-group">
          <label htmlFor="zip">Zip Code:</label>
          <input
            type="text"
            id="zip"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            pattern="\d{5}"
            title="Five digit zip code"
            size="10"
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit" className="submit-button">Get Quote</button>
      </form>
    </div>
  );
};

export default QuoteLandingPage;
