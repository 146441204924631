import React from 'react';
import './QuoteSummary.css';

const QuoteSummary = ({ quoteDetails, userInfo, onAccept, onDecline, loading }) => {
  if (!quoteDetails) {
    return <p>Loading...</p>;
  }

  const { detailedEstimate, estimate } = quoteDetails;
  const total = estimate.TotalAmt || 0;
  const discount = detailedEstimate.discount || 0;
  const address = detailedEstimate.address || '';


  const renderWindowDetails = (unitType, unitCounts, unitPrices) => {
    return Object.keys(unitCounts).map(story => {
      if (unitCounts[story] > 0) {
        return (
          <div key={story}>
            <span>{unitType} Panes ({story}): {unitCounts[story]}</span>
            <span> @ ${unitPrices[story]} each</span>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className="quote-summary left-align">
      <h2>Quote Summary</h2>
      <div className="left-align">
        <p><strong>Service Address:</strong> {address}</p>
      </div>
      <div className="left-align">
        <h3>Customer Information</h3>
        <p><strong>Name:</strong> {userInfo.name}</p>
        <p><strong>Email:</strong> {userInfo.email}</p>
        <p><strong>Phone:</strong> {userInfo.phone}</p>
      </div>
      <h3>Service Details</h3>
      <ul>
        {detailedEstimate.details.map((detail, index) => (
          <li key={index}>
            <strong>{detail.name}</strong>: ${detail.cost.toFixed(2)}
            <div>
              {typeof detail.units === 'object' ? (
                <div>
                  {Object.keys(detail.units).map(unitType => (
                    <div key={unitType}>
                      {renderWindowDetails(unitType, detail.units[unitType], detail.unitPrices[unitType])}
                    </div>
                  ))}
                </div>
              ) : (
                <span>Units: {parseFloat(detail.units).toFixed(2)}</span>
              )}
              {detail.unitPrice && typeof detail.unitPrice !== 'object' && (
                <span> @ ${parseFloat(detail.unitPrice).toFixed(2)} each</span>
              )}
              {detail.sqft && <span> (Square Footage: {detail.sqft})</span>}
              {detail.floors && <span> (Floors: {detail.floors})</span>}
              {detail.panelCount && <span> (Panels: {detail.panelCount})</span>}
              {detail.oilRustRemoval && <span> (Oil/Rust Stain Removal: Yes)</span>}
            </div>
          </li>
        ))}
      </ul>

      <h3>Total Quote: ${total.toFixed(2)} <i>+ tax</i></h3>
      <h4>Discount Applied: {discount * 100}%</h4>

      <p className="disclaimer">
        This quote is based on the provided information. Additional charges may apply if we need to measure the areas
        or if unexpected obstacles are encountered. We will contact you for a final confirmation before starting any work and if we need to schedule measurement.
      </p>
      <p>This quote will be delivered via email where it can be accepted or declined and is valid for 30 days.</p>
 
      
    </div>
  );
};

export default QuoteSummary;
