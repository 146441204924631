import axios from 'axios';

class QuotingService {
  constructor() {
    this.services = {
      softWash: { pricePerSqFt: 0.25, calculate: this.estimateSoftWashCost.bind(this) },
      roofCleaning: { pricePerSqFt: 0.25, calculate: this.estimateRoofCleaningCost.bind(this) },
      gutterCleaning: { basePrice: 150, pricePerLinearFoot: 0.75, calculate: this.estimateGutterCleaningCost.bind(this) },
      solarPanelCleaning: { basePrice: 6, calculate: this.estimateSolarPanelCleaningCost.bind(this) },
      dryerVentCleaning: { basePrice: 150, calculate: this.estimateDryerVentCleaningCost.bind(this) },
      windowCleaning: { basePrice: 80, calculate: this.estimateWindowCleaningCost.bind(this) },
      drivewayCleaning: { pricePerSqFt: 0.10, calculate: this.estimateDrivewayCleaningCost.bind(this) },
      deckCleaning: { pricePerLinearFt: 0.15, calculate: this.estimateDeckCleaningCost.bind(this) },
      graffitiRemoval: { basePrice: 50, calculate: this.estimateGraffitiRemovalCost.bind(this) },
      oilRustRemoval: { price: 100, calculate: () => 100 }
    };
  }

  estimateSoftWashCost(sqft, floors) {
    const averageWallHeightPerStory = 9;
    const footprint = sqft / floors;
    const perimeter = 4 * Math.sqrt(footprint);
    const totalWallHeight = floors * averageWallHeightPerStory;
    const totalWallSurfaceArea = perimeter * totalWallHeight;
    return totalWallSurfaceArea * this.services.softWash.pricePerSqFt;
  }

  estimateRoofCleaningCost(sqft, floors, pitchCategory) {
    const pitchFactors = { flat: 1.05, moderate: 1.2, steep: 1.3 };
    const pitchFactor = pitchFactors[pitchCategory.toLowerCase()];
    const footprint = sqft / floors;
    const roofSurfaceArea = footprint * pitchFactor;
    return roofSurfaceArea * this.services.roofCleaning.pricePerSqFt;
  }

  estimateGutterCleaningCost(sqft, floors) {
    const footprint = sqft / floors;
    const perimeter = 4 * Math.sqrt(footprint);
    const linearFeet = perimeter; // Perimeter of the house is the approximate linear feet of the gutters
    return this.services.gutterCleaning.basePrice + (linearFeet * this.services.gutterCleaning.pricePerLinearFoot);
  }

  estimateSolarPanelCleaningCost(panelCount, withOtherService) {
    let pricePerPanel;
    if (panelCount > 20) {
      pricePerPanel = 6;
    } else {
      pricePerPanel = 12 - (panelCount * 0.3);
    }

    const tripCharge = withOtherService ? 0 : 50;
    return tripCharge + (panelCount * pricePerPanel);
  }

  estimateDryerVentCleaningCost(floor) {
    const prices = { firstFloor: 150, secondFloor: 160, thirdFloorOrAbove: 200 };
    return prices[floor];
  }

  estimateWindowCleaningCost(windowCounts) {
    const normalPanes = { firstStory: 5, secondStory: 6.5, thirdStory: 10 };
    const frenchPanes = { firstStory: 1.3, secondStory: 1.5, thirdStory: 1.7 };
    const skylights = { firstPane: 15, additionalPane: 9 };
    const screens = { firstStory: 3.5, secondStory: 5.5, thirdStory: 3.5 };

    let cost = 0;

    // Normal Panes
    cost += windowCounts.normal.firstStory * normalPanes.firstStory;
    cost += windowCounts.normal.secondStory * normalPanes.secondStory;
    cost += windowCounts.normal.thirdStory * normalPanes.thirdStory;

    // French Panes
    cost += windowCounts.french.firstStory * frenchPanes.firstStory;
    cost += windowCounts.french.secondStory * frenchPanes.secondStory;
    cost += windowCounts.french.thirdStory * frenchPanes.thirdStory;

    // Skylights
    cost += windowCounts.skylight.firstPane * skylights.firstPane;

    // Screens
    cost += windowCounts.screen.firstStory * screens.firstStory;
    cost += windowCounts.screen.secondStory * screens.secondStory;
    cost += windowCounts.screen.thirdStory * screens.thirdStory;

    return cost;
  }

  estimateDrivewayCleaningCost(sqft) {
    return sqft * this.services.drivewayCleaning.pricePerSqFt;
  }

  estimateDeckCleaningCost(linearFt) {
    return linearFt * this.services.deckCleaning.pricePerLinearFt;
  }

  estimateGraffitiRemovalCost() {
    return this.services.graffitiRemoval.basePrice;
  }

  applyDiscounts(serviceSelections) {
    const selectedServicesCount = Object.values(serviceSelections).filter(Boolean).length;
    let discount = 0;
    if (selectedServicesCount >= 5) return discount = 0.15;
    if (selectedServicesCount >= 4) return discount = 0.10;
    if (selectedServicesCount >= 3) return discount = 0.05;
    return discount;
  }

  async getQuoteDetails(serviceSelections, sqft, floors, pitchCategory, address, userInfo) {
    const details = [];
    let total = 0;

    if (serviceSelections.softWash) {
      const cost = this.estimateSoftWashCost(sqft, floors);
      details.push({ name: 'Softwash of Exterior Home', cost, sqft });
      total += cost;
    }
    if (serviceSelections.roofCleaning) {
      const cost = this.estimateRoofCleaningCost(sqft, floors, pitchCategory);
      details.push({ name: 'Roof Cleaning', cost, sqft, floors });
      total += cost;
    }
    if (serviceSelections.gutterCleaning) {
      const cost = this.estimateGutterCleaningCost(sqft, floors);
      details.push({ name: 'Gutter Cleaning', cost, sqft, floors });
      total += cost;
    }
    if (serviceSelections.solarPanelCleaning) {
      const cost = this.estimateSolarPanelCleaningCost(serviceSelections.solarPanelCount, serviceSelections.softWash);
      details.push({ name: 'Solar Panel Cleaning', cost, panelCount: serviceSelections.solarPanelCount });
      total += cost;
    }
    if (serviceSelections.dryerVentCleaning) {
      const cost = this.estimateDryerVentCleaningCost(serviceSelections.dryerVentCleaning);
      details.push({ name: 'Dryer Vent Cleaning', cost });
      total += cost;
    }
    if (serviceSelections.windowCleaning) {
      const cost = this.estimateWindowCleaningCost(serviceSelections.windowCounts);
      details.push({ name: 'Window Cleaning', cost, windowCounts: serviceSelections.windowCounts });
      total += cost;
    }
    if (serviceSelections.drivewayCleaning) {
      const cost = this.estimateDrivewayCleaningCost(serviceSelections.drivewaySqFt);
      details.push({ name: 'Driveway, Sidewalk, & Patio Cleaning', cost, sqft: serviceSelections.drivewaySqFt });
      total += cost;
    }
    if (serviceSelections.oilRustRemoval) {
      const cost = this.services.oilRustRemoval.price;
      details.push({ name: 'Oil/Rust Stain Removal', cost });
      total += cost;
    }
    if (serviceSelections.deckCleaning) {
      const cost = this.estimateDeckCleaningCost(serviceSelections.deckLinearFt);
      details.push({ name: 'Deck & Fence Cleaning', cost, linearFt: serviceSelections.deckLinearFt });
      total += cost;
    }
    if (serviceSelections.graffitiRemoval) {
      const cost = this.estimateGraffitiRemovalCost();
      details.push({ name: 'Graffiti, Rust, & Oil Removal', cost });
      total += cost;
    }

    const discount = this.applyDiscounts(serviceSelections);
    const discountedTotal = total - (total * discount);

    const quoteDetails = { details, total: discountedTotal, discount, address };
    const response = await axios.post('https://zencleanbohapi.zencleanwa.com/api/quotes', { quoteDetails, userInfo, address });

    return response.data;
  }
}

export default QuotingService;
