import axios from 'axios';

const API_KEY = process.env.REACT_APP_HOUSE_LOOKUP_API_KEY;
const SERVICE_COUNTIES = ['King', 'Pierce', 'Snohomish', 'Kitsap', 'Thurston'];
const rentCastApi = axios.create({
  baseURL: 'https://api.rentcast.io/v1',
  headers: {
    'Accept': 'application/json',
    'X-Api-Key': API_KEY,
  }
});

export const getPropertyByAddress = async (address) => {
    try {
      const response = await rentCastApi.get(`/properties`, {
        params: { address }
      });
      const property = response.data.length > 0 ? response.data[0] : null;

    if (property && SERVICE_COUNTIES.includes(property.county)) {
      return property;
    } else {
      return null;
    }
    } catch (error) {
      console.error('Error fetching property data from RentCast:', error);
      throw error;
    }
  };
