import React, { useState, useEffect } from 'react';
import { FaHome, FaSolarPanel, FaWater, FaWind, FaCloudRain, FaWindowRestore, FaTree, FaSprayCan } from 'react-icons/fa';
import './ServiceSelection.css';

const ServiceSelection = ({ onSubmitServices }) => {
  const [services, setServices] = useState({
    softWash: true,
    roofCleaning: '',
    gutterCleaning: false,
    solarPanelCleaning: false,
    dryerVentCleaning: '',
    windowCleaning: false,
    drivewayCleaning: false,
    deckCleaning: false,
    graffitiRemoval: false,
    oilRustRemoval: false,
    windowCounts: {
      normal: { firstStory: 0, secondStory: 0, thirdStory: 0 },
      french: { firstStory: 0, secondStory: 0, thirdStory: 0 },
      skylight: { firstPane: 0 },
      screen: { firstStory: 0, secondStory: 0, thirdStory: 0 }
    },
    solarPanelCount: 0,
    drivewaySqFt: 0,
    deckLinearFt: 0,
  });

  const [openAccordion, setOpenAccordion] = useState(null);
  const [discount, setDiscount] = useState(0);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const nameParts = name.split('.');
    if (nameParts.length === 2) {
      const [type, story] = nameParts;
      setServices((prev) => ({
        ...prev,
        windowCounts: {
          ...prev.windowCounts,
          [type]: { ...prev.windowCounts[type], [story]: parseInt(value, 10) }
        }
      }));
    } else {
      setServices((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitServices(services);
  };

  const toggleAccordion = (service) => {
    setOpenAccordion(openAccordion === service ? null : service);
  };

  useEffect(() => {
    const selectedServicesCount = Object.values(services).filter(Boolean).length;
    if (selectedServicesCount >= 5) setDiscount(0.15);
    else if (selectedServicesCount >= 4) setDiscount(0.10);
    else if (selectedServicesCount >= 3) setDiscount(0.05);
    else setDiscount(0);
  }, [services]);

  return (
    <div className="service-selection">
      <h2>Select Services</h2>
      <form onSubmit={handleSubmit}>
        <div className="accordion">
          <div className="accordion-item">
            <div className="accordion-header" onClick={() => toggleAccordion('softWash')}>
              <FaWater /> Softwash of Exterior Home
            </div>
            {openAccordion === 'softWash' && (
              <div className="accordion-content">
                <p>Softwashing is a gentle yet effective cleaning method for the exterior of your home.</p>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      name="softWash"
                      checked={services.softWash}
                      onChange={handleChange}
                    />
                    Select Softwash of Exterior Home
                  </label>
                </div>
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div className="accordion-header" onClick={() => toggleAccordion('roofCleaning')}>
              <FaHome /> Roof Cleaning
            </div>
            {openAccordion === 'roofCleaning' && (
              <div className="accordion-content">
                <p>Roof cleaning services to remove moss, algae, and debris.</p>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      name="roofCleaning"
                      checked={services.roofCleaning}
                      onChange={handleChange}
                    />
                    Include Roof Cleaning
                  </label>
                </div>
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div className="accordion-header" onClick={() => toggleAccordion('gutterCleaning')}>
              <FaCloudRain /> Gutter Cleaning
            </div>
            {openAccordion === 'gutterCleaning' && (
              <div className="accordion-content">
                <p>Professional gutter cleaning to ensure proper water drainage.</p>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      name="gutterCleaning"
                      checked={services.gutterCleaning}
                      onChange={handleChange}
                    />
                    Select Gutter Cleaning
                  </label>
                </div>
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div className="accordion-header" onClick={() => toggleAccordion('solarPanelCleaning')}>
              <FaSolarPanel /> Solar Panel Cleaning
            </div>
            {openAccordion === 'solarPanelCleaning' && (
              <div className="accordion-content">
                <p>Cleaning services for solar panels to maintain their efficiency.</p>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      name="solarPanelCleaning"
                      checked={services.solarPanelCleaning}
                      onChange={handleChange}
                    />
                    Select Solar Panel Cleaning
                  </label>
                  {services.solarPanelCleaning && (
                    <label>
                      Number of Panels:
                      <input
                        type="number"
                        name="solarPanelCount"
                        value={services.solarPanelCount}
                        onChange={handleChange}
                      />
                    </label>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div className="accordion-header" onClick={() => toggleAccordion('dryerVentCleaning')}>
              <FaWind /> Dryer Vent Cleaning
            </div>
            {openAccordion === 'dryerVentCleaning' && (
              <div className="accordion-content">
                <p>Ensure your dryer vents are clean to prevent fires and improve efficiency.</p>
                <div className="checkbox-container">
                  <label>
                    Dryer Vent Location:
                    <select name="dryerVentCleaning" value={services.dryerVentCleaning} onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="firstFloor">First Floor</option>
                      <option value="secondFloor">Second Floor</option>
                      <option value="thirdFloorOrAbove">Third Floor or Above</option>
                    </select>
                  </label>
                </div>
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div className="accordion-header" onClick={() => toggleAccordion('windowCleaning')}>
              <FaWindowRestore /> Window Cleaning
            </div>
            {openAccordion === 'windowCleaning' && (
              <div className="accordion-content">
                <p>Professional window cleaning services for a clear view.</p>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      name="windowCleaning"
                      checked={services.windowCleaning}
                      onChange={handleChange}
                    />
                    Select Window Cleaning
                  </label>
                </div>
                {services.windowCleaning && (
                  <div className="table">
                    <div className="table-row">
                      <div className="table-cell"></div>
                      <div className="table-cell">First Story</div>
                      <div className="table-cell">Second Story</div>
                      <div className="table-cell">Third Story</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Normal Panes</div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="normal.firstStory"
                          value={services.windowCounts.normal.firstStory}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="normal.secondStory"
                          value={services.windowCounts.normal.secondStory}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="normal.thirdStory"
                          value={services.windowCounts.normal.thirdStory}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">French Panes</div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="french.firstStory"
                          value={services.windowCounts.french.firstStory}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="french.secondStory"
                          value={services.windowCounts.french.secondStory}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="french.thirdStory"
                          value={services.windowCounts.french.thirdStory}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Skylights</div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="skylight.firstPane"
                          value={services.windowCounts.skylight.firstPane}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Screens</div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="screen.firstStory"
                          value={services.windowCounts.screen.firstStory}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="screen.secondStory"
                          value={services.windowCounts.screen.secondStory}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="table-cell">
                        <input
                          type="number"
                          name="screen.thirdStory"
                          value={services.windowCounts.screen.thirdStory}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div className="accordion-header" onClick={() => toggleAccordion('drivewayCleaning')}>
              <FaTree /> Driveway, Sidewalk, & Patio Cleaning
            </div>
            {openAccordion === 'drivewayCleaning' && (
              <div className="accordion-content">
                <p>Keep your driveway, sidewalks, and patios clean and free of stains.</p>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      name="drivewayCleaning"
                      checked={services.drivewayCleaning}
                      onChange={handleChange}
                    />
                    Select Driveway, Sidewalk, & Patio Cleaning
                  </label>
                </div>
                {services.drivewayCleaning && (
                  <>
                    <label>
                      Square Footage:
                      <input
                        type="number"
                        name="drivewaySqFt"
                        value={services.drivewaySqFt}
                        onChange={handleChange}
                      />
                    </label>
                    <div className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          name="oilRustRemoval"
                          checked={services.oilRustRemoval}
                          onChange={handleChange}
                        />
                        Include Oil/Rust Stain Removal ($100)
                      </label>
                    </div>
                    <div className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          name="measureDriveway"
                          checked={services.measureDriveway}
                          onChange={handleChange}
                        />
                        Let us measure for you
                      </label>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div className="accordion-header" onClick={() => toggleAccordion('deckCleaning')}>
              <FaTree /> Deck & Fence Cleaning
            </div>
            {openAccordion === 'deckCleaning' && (
              <div className="accordion-content">
                <p>Deck and fence cleaning services to remove dirt, algae, and stains.</p>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      name="deckCleaning"
                      checked={services.deckCleaning}
                      onChange={handleChange}
                    />
                    Select Deck & Fence Cleaning
                  </label>
                </div>
                {services.deckCleaning && (
                  <>
                    <label>
                      Linear Footage:
                      <input
                        type="number"
                        name="deckLinearFt"
                        value={services.deckLinearFt}
                        onChange={handleChange}
                      />
                    </label>
                    <div className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          name="measureDeck"
                          checked={services.measureDeck}
                          onChange={handleChange}
                        />
                        Let us measure for you
                      </label>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <FaSprayCan /> Graffiti, Rust, & Oil Removal (Call for Quote)
            </div>
          </div>
        </div>
        <div className="discount-info">
          {discount * 100}% discount based on services selected
        </div>
        <button type="submit">Get Quote</button>
      </form>
    </div>
  );
};

export default ServiceSelection;
