import React, { useState, useEffect } from 'react';
import QuoteLandingPage from './components/QuoteLandingPage';
import HouseConfirmation from './components/HouseConfirmation';
import ServiceSelection from './components/ServiceSelection';
import UserInfoForm from './components/UserInfoForm';
import QuoteSummary from './components/QuoteSummary';
import ScheduleOrDecline from './components/ScheduleOrDecline';
import { getPropertyByAddress } from './services/rentcastService';
import QuotingService from './services/quotingService';
import './App.css';
import logo from './assets/logo.png';
import { logPageView, logEvent } from './analytics';
import { updateQuote, createQuote } from './services/apiService';

const quotingService = new QuotingService();
const addressCache = {}; // Cache object

function App() {
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState('');
  const [houseData, setHouseData] = useState(null);
  const [outOfServiceArea, setOutOfServiceArea] = useState(false);
  const [commercialProperty, setCommercialProperty] = useState(false);
  const [services, setServices] = useState({});
  const [quoteDetails, setQuoteDetails] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quoteId, setQuoteId] = useState(null); // New state for quote ID

  useEffect(() => {
    logPageView();
  }, [step]);

  const handleSubmitAddress = async (address) => {
    logEvent('event', 'Submit Address', address);
    setAddress(address);

    // Check the cache first
    if (addressCache[address]) {
      setHouseData(addressCache[address]);
      setStep(2);
      return;
    }

    try {
      const propertyData = await getPropertyByAddress(address);
      if (!propertyData) {
        setOutOfServiceArea(true);
      } else {
        // Update the cache
        addressCache[address] = propertyData;
        setHouseData(propertyData);
        setStep(2);
      }
    } catch (error) {
      setHouseData(null);
    }
  };

  const handleConfirmHouse = (confirmed, floors, exteriorMaterial, roofPitch, squareFootage) => {
    logEvent('event', 'Confirm House', confirmed ? 'Yes' : 'No');
    if (confirmed) {
      setHouseData({ ...houseData, floors, exteriorMaterial, roofPitch, squareFootage });
      setStep(3);
    } else {
      setStep(1); // Return to address input if house not confirmed
    }
  };

  const handleSubmitServices = async (selectedServices) => {
    logEvent('event', 'Submit Services', JSON.stringify(selectedServices));
    setServices(selectedServices);
    setStep(4);
  };

  const handleSubmitUserInfo = async (userInfo) => {
    logEvent('event', 'Submit User Info', JSON.stringify(userInfo));
    setUserInfo(userInfo);
    const { squareFootage, floors, roofPitch } = houseData;
    const quoteRequestData = {
      serviceSelections: services,
      sqft: squareFootage,
      floors,
      pitchCategory: roofPitch,
      address,
      userInfo
    };

    try {
      const response = await createQuote(quoteRequestData); // Create a new quote using the API
      setQuoteDetails({ detailedEstimate: response.detailedEstimate, estimate: response.estimate });
      logEvent('event', 'conversion_event_request_quote', response.detailedEstimate)
      setQuoteId(response._id);
      setStep(5);
    } catch (error) {
      console.error('Error generating quote:', error);
    }
  };

  const handleAcceptQuote = async () => {
    logEvent('User Flow', 'Accept Quote', JSON.stringify(quoteDetails));
    setLoading(true);
    await updateQuote(quoteId, { accepted: true, userInfo });
    setLoading(false);
    setStep(6);
  };

  const handleDeclineQuote = async () => {
    logEvent('User Flow', 'Decline Quote', JSON.stringify(quoteDetails));
    setLoading(true);
    await updateQuote(quoteId, { accepted: false, userInfo });
    setLoading(false);
    setStep(1); // Start over
  };

  const handleSchedule = () => {
    logEvent('User Flow', 'Schedule', JSON.stringify(quoteDetails));
    window.location.href = 'https://www.zencleanwa.com';
  };

  return (
    <div className="App">
      <img src={logo} width="400" alt="Company Logo" className="logo" />
      {outOfServiceArea && (
        <div>
          <h2>Out of Service Area</h2>
          <p>We currently do not service your area. Please sign up for notifications when we expand to your location.</p>
          {/* Add marketing mail signup form here */}
          <button onClick={() => setStep(1)}>Back</button>
        </div>
      )}
      {commercialProperty && (
        <div>
          <h2>Commercial Property</h2>
          <p>For commercial property quotes, please call 206-705-8794 or email quote@zencleanwa.com.</p>
          <button onClick={() => setStep(1)}>Back</button>
        </div>
      )}
      {step === 1 && <QuoteLandingPage onSubmitAddress={handleSubmitAddress} />}
      {!outOfServiceArea && !commercialProperty && step === 2 && <HouseConfirmation houseData={houseData} onConfirm={handleConfirmHouse} />}
      {!outOfServiceArea && !commercialProperty && step === 3 && <ServiceSelection onSubmitServices={handleSubmitServices} />}
      {!outOfServiceArea && !commercialProperty && step === 4 && <UserInfoForm onSubmitUserInfo={handleSubmitUserInfo} />}
      {!outOfServiceArea && !commercialProperty && step === 5 && quoteDetails && (
        <QuoteSummary
          quoteDetails={quoteDetails}
          userInfo={userInfo}
          onAccept={handleAcceptQuote}
          onDecline={handleDeclineQuote}
          loading={loading}
        />
      )}
      {!outOfServiceArea && !commercialProperty && step === 6 && <ScheduleOrDecline onSchedule={handleSchedule} />}
    </div>
  );
}

export default App;
