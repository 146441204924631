import ReactGA from 'react-ga';
const TRACKING_ID = 'G-V32BNXP6BZ'; // Replace with your actual tracking ID
ReactGA.initialize(TRACKING_ID);

export const logPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const logEvent = (category = '', action = '', label = '') => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};

export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
